import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import './ProjectCard.css';

interface ProjectCardProps {
  project: string;
  href?: string | undefined;
}

const projectImages: Record<string, string> = {
  zapytai: 'project1.png',
  yaktobi: 'project2.png',
  inprogress: 'project3.png'
};

export const ProjectCard: React.FC<ProjectCardProps> = ({ project, href }) => {
  const { translations, currentLang } = useLanguage();
  const projectData = translations?.[currentLang]?.projectCards?.[project];

  console.log('Current Language:', currentLang);
  console.log('Project:', project);
  console.log('Project Data:', projectData);
  console.log('Translations:', translations);

  if (!projectData) {
    return null;
  }

  const Card = (
    <div className="project-card">
      <h3 className="project-title">{projectData.title}</h3>
      <p className="project-description">{projectData.description}</p>
      <img 
        src={`/images/${projectImages[project]}`} 
        alt={`${projectData.title} Project`} 
        className="project-image"
      />
    </div>
  );

  return href ? (
    <a href={href} className="project-link">
      {Card}
    </a>
  ) : Card;
}; 