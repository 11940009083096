import { Translation } from '../../types';

export const ua: Translation = {
  projects: "Проекти",
  profile: {
    description: "Перетворюю ідеї в SaaS продукти в MarTech | Захоплений стартапами, криптовалютою та новітніми технологіями.",
    followLinkedIn: "Слідкуйте за моїм LinkedIn профілем:"
  },
  projectCards: {
    zapytai: {
      title: "ZapytAI",
      description: "Ваш помічник для пошуку відповідей. Завантажуйте CSV файли та отримуйте швидкі та точні відповіді на ваші запитання."
    },
    yaktobi: {
      title: "Yaktobi",
      description: "Наш сервіс допомагає вивчити шлях клієнта та зібрати глибоку аналітику, як онлайн бізнесу, так і оффлайн."
    },
    inprogress: {
      title: "В розробці",
      description: "Новий проект у розробці. Слідкуйте за оновленнями, щоб дізнатися більше про майбутні можливості та функції."
    }
  }
}; 