import React, { createContext, useContext, useState } from 'react';
import { translations } from '../data/translations/index';
import { Language, Translations } from '../types';

interface LanguageContextType {
  currentLang: Language;
  setCurrentLang: (lang: Language) => void;
  translations: Translations;
}

const LanguageContext = createContext<LanguageContextType>({
  currentLang: 'en',
  setCurrentLang: () => {},
  translations
});

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentLang, setCurrentLang] = useState<Language>('en');

  const value: LanguageContextType = {
    currentLang,
    setCurrentLang,
    translations
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}; 