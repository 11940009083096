import React from 'react';
import { ProjectCard } from '../ProjectCard';
import { useLanguage } from '../../context/LanguageContext';
import './ProjectsSection.css';

export const ProjectsSection: React.FC = () => {
  const { translations, currentLang } = useLanguage();
  const projectsTitle = translations?.[currentLang]?.projects;
  const projectCards = translations?.[currentLang]?.projectCards;

  if (!translations?.[currentLang]) {
    console.log('No translations found for:', currentLang);
    return null;
  }

  return (
    <div className="projects-section">
      <h1>{projectsTitle}</h1>
      
      {projectCards?.zapytai && (
        <ProjectCard 
          project="zapytai" 
          href="https://zapytai.antonkovalenko.com" 
        />
      )}
      
      {projectCards?.yaktobi && (
        <ProjectCard 
          project="yaktobi" 
        />
      )}
      
      {projectCards?.inprogress && (
        <ProjectCard 
          project="inprogress" 
        />
      )}
    </div>
  );
}; 