import React from 'react';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import { ProjectsSection } from './components/ProjectsSection';
import { Profile } from './components/Profile';
import { LanguageProvider } from './context/LanguageContext';
import './App.css';

const App: React.FC = () => {
  return (
    <LanguageProvider>
      <LanguageSwitcher />
      <div className="container">
        <ProjectsSection />
        <Profile />
      </div>
    </LanguageProvider>
  );
};

export default App; 