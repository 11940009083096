import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import './Profile.css';

export const Profile: React.FC = () => {
  const { translations, currentLang } = useLanguage();
  const profileData = translations?.[currentLang]?.profile;

  if (!profileData) {
    return null; // или какой-то fallback компонент
  }

  return (
    <div className="profile-section">
      <img src="/images/profile.jpg" alt="Anton Kovalenko" className="profile-image" />
      <h2>Anton Kovalenko</h2>
      <p className="profile-description">
        {profileData.description}
        <br /><br />
        {profileData.followLinkedIn}{' '}
        <a 
          href="https://www.linkedin.com/in/anton-kovalenko-head-of-product/" 
          target="_blank" 
          rel="noopener noreferrer"
          className="linkedin-link"
        >
          @anton-kovalenko-head-of-product
        </a>
      </p>
    </div>
  );
}; 