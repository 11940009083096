import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import './LanguageSwitcher.css';

export const LanguageSwitcher: React.FC = () => {
  const { currentLang, setCurrentLang } = useLanguage();

  const handleLanguageChange = (lang: 'en' | 'ua') => {
    console.log('Switching to language:', lang);
    setCurrentLang(lang);
  };

  return (
    <div className="language-switcher">
      <button 
        className={`language-btn ${currentLang === 'en' ? 'active' : ''}`}
        onClick={() => handleLanguageChange('en')}
      >
        En
      </button>
      <span className="language-divider">|</span>
      <button 
        className={`language-btn ${currentLang === 'ua' ? 'active' : ''}`}
        onClick={() => handleLanguageChange('ua')}
      >
        Ua
      </button>
    </div>
  );
}; 