import { Translation } from '../../types';

export const en: Translation = {
  projects: "Projects",
  profile: {
    description: "Turning ideas into SaaS products in MarTech | Obsessed with startups, crypto, and the latest in tech.",
    followLinkedIn: "Follow my LinkedIn profile:"
  },
  projectCards: {
    zapytai: {
      title: "ZapytAI",
      description: "Your assistant for finding answers. Upload CSV files and get quick, accurate responses to your questions."
    },
    yaktobi: {
      title: "Yaktobi",
      description: "Our service helps analyze the customer journey and gather in-depth analytics for both online and offline businesses."
    },
    inprogress: {
      title: "Inprogress",
      description: "Leverage data to optimize customer lifecycle (acquisition, engagement, retention). Follow for insights!"
    }
  }
}; 